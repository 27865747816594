

import { Vue, Component, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class ManageAdd extends Vue {
  @Ref('form') readonly form!: ElForm
  private formData = {
    materialTypeName: '', // 分类名称
    materialTypeCode: '', // 分类编号
    parentMaterialTypeId: '1', // 上级分类
    state: '1' // 状态1启用2禁用
  } as any

  private materialTypeData = [] // 上级分类

  private rules = {
    materialTypeName: [
      { required: true, message: '请输入分类名称', trigger: 'blur' }
    ],
    materialTypeCode: [
      { required: true, message: '请输入物料编码', trigger: 'blur' }
    ],
    parentMaterialTypeId: [
      { required: true, message: '请选择上级分类', trigger: 'blur' }
    ],
    state: [
      { required: true, message: '请选择状态', trigger: 'blur' }
    ]
  }

  get materialTypeId () {
    return this.$route.query.materialTypeId as string
  }

  created () {
    console.log('materialTypeId', this.$route.query)
    if (this.materialTypeId) {
      this.formData = this.$route.query
    }
    this.loadMaterialType() // 获取上级分类
  }

  loadMaterialType () {
    this.$axios.get(this.$apis.material.selectYhMaterialTypeByList, {
      state: '1'
    }).then(res => {
      // console.log('类型', res)
      res.unshift({
        materialTypeId: '1',
        materialTypeName: '无'
      })
      this.materialTypeData = res
    })
  }

  submit () {
    this.form.validate(valid => {
      if (valid) {
        const url = this.materialTypeId ? this.$apis.material.updateYhMaterialType : this.$apis.material.insertYhMaterialType
        this.$axios.post(url, this.formData).then(() => {
          this.$router.back()
        })
      }
    })
  }
}
